/* eslint-disable class-methods-use-this */
import { apiService } from './api';

class TestFormService {

    fetchSubmissionToken(notificationId) {
        //console.log('inside fetchSubmissionToken');
    return apiService({
      method: 'get',
      url: `TestNotificationSet('${notificationId}')`,
      headers: { 'X-CSRF-Token': 'Fetch' }
    });
  }
    
    indexOfSpecialChar(string, startIndex = 0) {
        const specialChars = '/~!@#$%^&*()_-+=|\'<>';
        for (let i = 0; i < specialChars.length; i++) {
            const specialCharIndex = string.indexOf(specialChars[i], startIndex);
            if (specialCharIndex > -1) return specialCharIndex;
        }
        return -1;
    }

    submitForm(notificationId, data) {

        let dataStr = JSON.stringify(data);

        // encode any special characters
        let currentPos = this.indexOfSpecialChar(dataStr);

        while (currentPos > -1) {
            const hexVal = dataStr.charCodeAt(currentPos).toString(16);
            dataStr = dataStr.substring(0, currentPos) + '{w1e' + hexVal + '}' + dataStr.substring(currentPos + 1);
            currentPos = this.indexOfSpecialChar(dataStr);
        }

        //console.log('dataStr', dataStr);

        data = JSON.parse(dataStr);

    return apiService({
      method: 'put',
      url: `TestFormSet('${notificationId}')`,
      data,
    });
  }

  removeAssembly(notificationId, data) {
    const removingData = data;
    removingData.TestType = 'RM';

    return apiService({
      method: 'put',
      url: `TestFormSet('${notificationId}')`,
      data: removingData,
    });
  }
}

export const testFormService = new TestFormService();
