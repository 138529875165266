import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import {
  Formik,
  Field,
  Form,
  ErrorMessage,
} from 'formik';
import { ReCaptcha } from 'react-recaptcha-google';
import FormContext from '../../context/form-context';

const validationSchema = Yup.object().shape({
  notificationId: Yup.string()
    .required('Required'),
});

const redirectToPDFForm = () => {
    window.open('https://www.waterone.org/DocumentCenter/View/143', '_blank');
}

const FindByNotificationId = ({ onSubmit }) => {

    const formContext = useContext(FormContext);

    return (
        <Formik
            initialValues={{ notificationId: '' }}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
        >
            {
                ({ errors, touched, isSubmitting }) => (
                    <Form>
                        <div className="form-group">
                            <label name="notificationId">Notification #</label>
                            <Field
                                type="text"
                                name="notificationId"
                                className={errors.email && touched.email ? 'form-control error' : 'form-control'}
                            />
                            <ErrorMessage className="form-text text-danger" name="notificationId" component="div" />
                            <div className="form-text text-danger">{errors.notificationIdError}</div>
                        </div>
                        <div className="text-center">
                            {
                                !formContext.isRECAPTCHAVerified && (
                                    <ReCaptcha
                                        size="compact"
                                        render="explicit"
                                        sitekey="6LfsddwUAAAAAPyIDokSB6JMjpwpG2xwzw_79IGS"
                                        onloadCallback={() => { document.getElementById("g-recaptcha").style.display = 'inline-block'; }}
                                        verifyCallback={(token) => { formContext.updateState({ reCAPTCHAToken: token }); }}
                                        expiredCallback={() => { formContext.updateState({ reCAPTCHAToken: '' }); }}
                                />)
                            }
                            <button type="submit" style={{ verticalAlign: "top" }} className="btn btn-primary" disabled={ isSubmitting || formContext.reCAPTCHAToken == '' }>
                                Search
                        </button>
                        </div>

                        <br />

                        <div>
                            If not an annual Test, please select one of the following:
                        </div>

                        <div className="btn-group btn-group-sm" role="group">
                            <button className="btn btn-outline-primary btn-sm" type="button" onClick={redirectToPDFForm}>
                                New Assembly
                </button>&nbsp;
                <button className="btn btn-outline-primary btn-sm" type="button" onClick={redirectToPDFForm}>
                                Remove Assembly
                </button>&nbsp;
                <button className="btn btn-outline-primary btn-sm" type="button" onClick={redirectToPDFForm}>
                                Replace Assembly
                </button>
                        </div>
                        <div>Email: <a href='mailto: DWQ@waterone.org'>DWQ@waterone.org</a><br />
                            Phone: 913-895-1815
                            <br /><a href='/notifications' target='_blank'>Current Notification List</a></div>
                    </Form>
                )
            }
        </Formik>
        );
    }

FindByNotificationId.propTypes = {
  onSubmit: PropTypes.func.isRequired,
};

export default FindByNotificationId;
