import React from 'react';
import PropTypes from 'prop-types';
import * as Yup from 'yup';
import { Formik, Field, ErrorMessage } from 'formik';
import { Form } from 'react-formik-ui';

import { Select } from 'react-formik-ui';
import { ReCaptcha } from 'react-recaptcha-google';
import Autosuggest from 'react-autosuggest'

import { manufacturersService } from '../../services/manufacturers-service';
import FormContext from '../../context/form-context';

const initialValues = { manufacturer: '', assemblyType: '', model: '', serial: '' };
const validationSchema = Yup.object().shape({
    manufacturer: Yup.string().required('Required'),
    assemblyType: Yup.string().required('Required'),
    model: Yup.string().required('Required'),
    serial: Yup.string().required('Required'),
});

class FindByManufacturer extends React.Component {
  static contextType = FormContext
  constructor() {
      super();

      this.state = {
        reCAPTCHAToken: '',
        manufacturers: [],
        models: [],
        assemblyTypes: [],
        //manufacturer_assemblyType_models: [],
          filteredModels: [],
        model: ''
    };

    this.getManufacturers = this.getManufacturers.bind(this);
    this.getModels = this.getModels.bind(this);
    this.getAssemblyTypes = this.getAssemblyTypes.bind(this);
  }

    componentDidMount() {
        this.getManufacturers();
        this.getModels();
        this.getAssemblyTypes();
  }

  getManufacturers() {
    manufacturersService.getAll()
        .then((response) => {
        // filter out empty values
        const manufacturers = JSON.parse(response.data).d.results.filter(m => m.Name !== '');
        this.setState({
          manufacturers: manufacturers.map(m => ({ value: m.Name, label: m.Name })),
        });
      });
  }

    getAssemblyTypes() {
        manufacturersService.getAssemblyTypes()
            .then((response) => {
                // filter out empty values
                const assemblyTypes = JSON.parse(response.data).d.results.filter(m => m.Type !== '');
                this.setState({
                    assemblyTypes: assemblyTypes.map(m => ({ value: m.Type, label: m.Type.substring(3) })),
                });
            });
    }

  getModels() {
    manufacturersService.getModels()
      .then((response) => {
        // filter out empty values
        const models = JSON.parse(response.data).d.results.filter(m => m.ModelNo !== '');
        this.setState({
          models: models.map(m => ({ ModelNo: m.ModelNo, Manufacturer: m.Manufacturer, AssemblyType: m.AssemblyType })),
        });
      });
  }

    redirectToPDFForm = () => {
        window.open('/notifications', '_blank');
    }

  render() {
      const { manufacturers, models, assemblyTypes, filteredModels } = this.state;
      const { onSubmit } = this.props;

      //console.log('setFieldValue');
      //console.log(this.Formik.setFieldValue);
      //console.log(this.context);
      //console.log(this.props);

      //console.log(this.state);

    return (
      <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={onSubmit}
      >
        {
          ({ errors, values, isSubmitting, setFieldValue }) => {

            const modelDisabled = (values.manufacturer === '' || values.assemblyType === '');
            const serialNumberDisabled = (values.model === '' || modelDisabled);

            let manufacturer_assemblyType_models = [];
            if (!modelDisabled) {
                manufacturer_assemblyType_models = models.filter(m => m.AssemblyType === values.assemblyType && m.Manufacturer === values.manufacturer);
                manufacturer_assemblyType_models = manufacturer_assemblyType_models.map(m => m.ModelNo );
            }

            return (
              <Form>
                <div className="form-group">
                        <label htmlFor="manufacturer">Manufacturer</label>
                        <div className="dropdown">
                            <Select
                                name="manufacturer"
                                options={manufacturers}
                                placeholder="Select an option"
                                />
                        </div>
                  <ErrorMessage className="form-text text-danger" name="manufacturer" component="div" />
                </div>

                <div className="form-group">
                    <label htmlFor="assemblyType">Assembly Type</label>
                    <div className="dropdown">
                        <Select
                                name="assemblyType"
                                options={assemblyTypes}
                                placeholder="Select an option"
                                />
                    </div>
                    <ErrorMessage className="form-text text-danger" name="assemblyType" component="div" />
                </div>

                <div className="form-group">
                        <label htmlFor="model">Model</label>
                        <div className="typeahead">
                            <Autosuggest
                                inputProps={{
                                    name: "model",
                                    disabled: modelDisabled,
                                    value: values.model,
                                    onChange: (event, { newValue }) => {
                                        this.setState({ model: newValue });
                                        setFieldValue("model", newValue);
                                    },
                                    autoComplete: "off"
                                }}
                                suggestions={filteredModels}
                                onSuggestionsFetchRequested={({ value }) => {
                                    if (!value) {
                                        this.setState({ filteredModels: [] });
                                        return;
                                    }
                                    this.setState({ filteredModels: manufacturer_assemblyType_models.filter(m => m.toLowerCase().startsWith(value.toLowerCase())) });
                                }}
                                onSuggestionsClearRequested={() => {
                                    this.setState({ filteredModels: [] });
                                }}
                                getSuggestionValue={suggestion => suggestion}
                            renderSuggestion={suggestion => <div>{suggestion}</div>}
                                onSuggestionSelected={(event, { suggestion, method }) => {
                                if (method === "enter") {
                                    event.preventDefault();
                                }
                                this.setState({ model: suggestion });
                                setFieldValue("model", suggestion);
                            }}
                            />
                        </div>
                        {/*<TypeAhead
                    name="model"
                    suggestions={manufacturer_assemblyType_models}
                    disabled={modelDisabled}
                  />*/}
                  <ErrorMessage className="form-text text-danger" name="model" component="div" />
                </div>

                <div className="form-group">
                  <label htmlFor="serial">Serial #</label>
                  <Field
                    name="serial"
                    className="form-control"
                    disabled={serialNumberDisabled}
                  />
                  <ErrorMessage className="form-text text-danger" name="serial" component="div" />
                </div>

                    <div className="text-center">
                        
                        <div className="form-text text-danger mb-20">{errors.manufacturersError}</div>
                        {
                            !this.context.isRECAPTCHAVerified && (
                                <ReCaptcha
                                    size="compact"
                                    render="explicit"
                                    sitekey="6LfsddwUAAAAAPyIDokSB6JMjpwpG2xwzw_79IGS"
                                    onloadCallback={() => { document.getElementById("g-recaptcha").style.display = 'inline-block'; }}
                                    verifyCallback={(token) => { this.context.updateState({ reCAPTCHAToken: token }); }}
                                    expiredCallback={() => { this.context.updateState({ reCAPTCHAToken: '' }); }}
                            />)
                        }
                        <button className="btn btn-primary" style={{ verticalAlign: "top" }} type="submit" disabled={isSubmitting || this.context.reCAPTCHAToken == ''}>
                            Search
                        </button>
                    </div>

                    <br />

                    <div>
                        If not an annual Test, please select one of the following:
                    </div>

                    <div className="btn-group btn-group-sm" role="group">
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.redirectToPDFForm}>
                            New Assembly
                        </button>&nbsp;
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.redirectToPDFForm}>
                            Remove Assembly
                        </button>&nbsp;
                        <button className="btn btn-outline-primary btn-sm" type="button" onClick={this.redirectToPDFForm}>
                            Replace Assembly
                        </button>
                    </div>
                </Form>
            );
          }
        }
      </Formik>
    );
  }
}

FindByManufacturer.propTypes = {
  onSubmit: PropTypes.func.isRequired
};

export default FindByManufacturer;
