import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import W1DatePicker from '../W1DatePicker/W1DatePicker';

const renderInput = (name, label, placeholder, key) => (
  <div className="col-12" key={key}>
        <div className="form-group">
            {
                placeholder != '' && (
                    <div className="row">
                        <div className="col-3 text-right align-self-center">&nbsp;</div>
                        <div className="col-4">
                            <label>{placeholder}</label>
                        </div>
                    </div>
                )
            }
          <div className="row">
            <div className="col-3 text-right align-self-center">
              <div>{label}</div>
            </div>
            <div className="col-4">
                <Field name={name} type="text" className="form-control" onClick={(e) => { e.target.select(); }} />
                <ErrorMessage className="form-text text-danger" name={name} component="div" />
            </div>
          </div>
    </div>
  </div>
);

const renderFields = (assemblyType) => {
  const fields = [];

  switch (assemblyType) {
    case 'BF-RPA':
    case 'BF-RPDA':
      fields.push(renderInput('MainCheckValve1Psid', 'Check Valve 1', 'PSID', fields.length));
      fields.push(renderInput('MainCheckValve2Psid', 'Check Valve 2', '', fields.length));
      fields.push(renderInput('MainReliefValvePsid', 'Relief Valve', '', fields.length));
          break;
    case 'BF-RPDA-II':
      fields.push(renderInput('MainCheckValve1Psid', 'Check Valve 1', 'PSID', fields.length));
      fields.push(renderInput('MainCheckValve2Psid', 'Check Valve 2', '', fields.length));
      fields.push(renderInput('MainReliefValvePsid', 'Relief Valve', '', fields.length));
      fields.push(renderInput('BypassCheckValve1Psid', 'Bypass Valve', '', fields.length));
          break;
    case 'BF-DCA':
    case 'BF-DCDA':
      fields.push(renderInput('MainCheckValve1Psid', 'Check Valve 1', 'PSID', fields.length));
      fields.push(renderInput('MainCheckValve2Psid', 'Check Valve 2', '', fields.length));
          break;
    case 'BF-DCDA-II':
      fields.push(renderInput('MainCheckValve1Psid', 'Check Valve 1', 'PSID', fields.length));
      fields.push(renderInput('MainCheckValve2Psid', 'Check Valve 2', '', fields.length));
      fields.push(renderInput('BypassCheckValve1Psid', 'Bypass Valve', '', fields.length));
      break;
    case 'BF-PVB':
    case 'BF-SPVB':
      fields.push(renderInput('VBAirInletPsid', 'Air Inlet', 'Opened At', fields.length));
      fields.push(renderInput('VBChkVlvPsid', 'Check Valve', 'Held At', fields.length));
      break;
    case "BF-AG":
      fields.push(renderInput('AirGapSupplySize', 'Pipe Diameter', 'Inches', fields.length));
      fields.push(renderInput('AirGap', 'Air Gap Distance', '', fields.length));
      break;
    default:
      fields.push((<div />));
      break;
  }

  return fields;
};

const AssemblyTypeFields = ({ assemblyType, assemblyTypeDscrpt }) => (
  <React.Fragment>
        <h2>Passing Backflow Assembly Test</h2>
        <div className="row">
        <div className="col-12">
            <div className="form-group">
                <div className="row">
                    <div className="col-3 text-right align-self-center">
                        <div>Test Date</div>
                    </div>
                    <div className="col-4">
                        <W1DatePicker name="TestDate" />
                        <ErrorMessage className="form-text text-danger" name="TestDate" component="div" />
                    </div>
                    <div className="col-5">&nbsp;</div>
                </div>
            </div>
            </div>
            </div>

    <div className="row">
      {renderFields(assemblyType)}
    </div>
  </React.Fragment>
);

AssemblyTypeFields.defaultProps = {
  assemblyType: '',
};

AssemblyTypeFields.propTypes = {
  assemblyType: PropTypes.string,
};

export default AssemblyTypeFields;
