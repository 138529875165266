/* eslint-disable react/prop-types */
import React from 'react';
import { withFormik, Field, ErrorMessage } from 'formik';
import { Textarea } from 'react-formik-ui';
import * as Yup from 'yup';
import { dateToHuman } from '../../helpers/util';

import { testersService } from '../../services/testers-service';
import { testFormService } from '../../services/test-form-service';

import SearchInput from '../SearchInput/SearchInput';
import W1DatePicker from '../W1DatePicker/W1DatePicker';
import AssemblyTypeFields from '../AssemblyTypeFields/AssemblyTypeFields';
import './RecordForm.css';
import ModalDialog from '../ModalDialog/ModalDialog';
import { Checkbox } from '../Input';
import { parsePhoneNumberFromString } from 'libphonenumber-js'

class RecordForm extends React.Component {
  constructor() {
    super();
    this.onSubmit = this.onSubmit.bind(this);
      this.state = {
          isOpen: true,
          IsIncorrectInformation: false
      };
      this.textInput = React.createRef();
      this.focusTextInput = this.focusTextInput.bind(this);
    }

    focusTextInput() {
        // Explicitly focus the text input using the raw DOM API
        // Note: we're accessing "current" to get the DOM node
        //setTimeout(() => {
            this.textInput.current.focus();
        //}, 0);
    }

  onSubmit = () => {
  };

    redoSearchClick = () => {
        this.setState({ isOpen: false });
        this.props.updateState({
            redirectToForm: false,
            redirectToHome: true
        });
    }

    okClick = () => {
        this.setState({ isOpen: false });
        this.props.updateState({
            redirectToForm: false,
            redirectToHome: false
        });
        this.focusTextInput();
    }

    render() {  
    const {
      values,
      isSubmitting,
      setFieldValue,
        setFieldError,
      setErrors,
      handleBlur,
      status,
        submitForm,
      handleReset,
    } = this.props;

    const { onSubmit } = this;
    const onCaptchaResolved = () => {
      submitForm();
    };

        const { isOpen } = this.state;

        const inCorrectInfoClick = (event) => {
            this.setState({ isOpen: false, IsIncorrectInformation: true });
            this.focusTextInput();
        }

        const handleCheckboxOnChange = (event) => {
            this.setState({ IsIncorrectInformation: !this.state.IsIncorrectInformation });
            if (!this.state.IsIncorrectInformation)
                this.props.values.TestComments = '';
        }

        const formatPhone = (phonestring) => {
            if (phonestring) {
                var tmp = parsePhoneNumberFromString('+1' + phonestring);
                if (tmp)
                    return (tmp.formatNational());
            }
            return ('');
        }

        const findTesterByCertificationId = (event) => {
      //handleBlur(event);
      const id = event.target.value || '';

            if (id) {
                //console.log('id=' + id);
        testersService.findTesterByCertificationId(id)
          .then((response) => {
            const { results } = JSON.parse(response.data).d;
            if (results.length === 1) {
                const { __metadata, ...tester } = results[0]; // filterout __metadata.

              //console.log(tester);
                setErrors({});

              setFieldValue('TesterCertificationNo', tester.CertificateNo);
              setFieldValue('TesterCertificationExpDate', tester.CertificateExpDate);
              setFieldValue('TesterFirstName', tester.Name.split(',')[1]);
              setFieldValue('TesterLastName', tester.Name.split(',')[0]);
              setFieldValue('TesterPhone', tester.TelephoneNo1);
              setFieldValue('TesterCompanyName', tester.Company);
              setFieldValue('TesterCompanyPhone', tester.TelephoneNo2);
              setFieldValue('TesterCompanyFax', tester.Fax);
              setFieldValue('TesterEmail', tester.Email);
            } else {
              throw Error('Tester Certification No not found');
            }
          })
            .catch((err) => {
                var errorMessage = '';
                if (err.response.data.includes('Invalid token detected at position 17'))
                    errorMessage = 'Please verify the certification number is entered exactly as used by the certifying agency.';
                else {
                    const { error } = JSON.parse(err.response.data);
                    errorMessage = error.message.value;
                }
            setFieldError('TesterCertificationNo', errorMessage);

            //setFieldValue('TesterCertificationNo', '');
            setFieldValue('TesterCertificationExpDate', '');
            setFieldValue('TesterFirstName', '');
            setFieldValue('TesterLastName', '');
            setFieldValue('TesterPhone', '');
            setFieldValue('TesterCompanyName', '');
            setFieldValue('TesterCompanyPhone', '');
            setFieldValue('TesterCompanyFax', '');
            setFieldValue('TesterEmail', '');
            //this.focusTextInput();
          });
      }
    };

      return (
      <div id="RecordForm">
              <ModalDialog isOpen={isOpen} onCloseClick={this.okClick}>
                  <h4>Verify Information</h4>
                  <p>Please verify the information on the left is accurate.  If not, please click the "Incorrect Information" button.</p>
                  <button className="btn btn-primary" type="button" onClick={this.okClick}>
                        OK
                  </button>&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-primary" type="button" onClick={this.redoSearchClick}>
                        Redo Search
                  </button>&nbsp;&nbsp;&nbsp;
                  <button className="btn btn-primary" type="button" onClick={inCorrectInfoClick}>
                        Incorrect Information
                  </button>
              </ModalDialog>
        <form className="form-horizontal" role="form">
          <Field type="hidden" name="TestType" />
          <Field type="hidden" name="TestDate" />
          <Field type="hidden" name="NotificationNo" />
          <Field type="hidden" name="NotificationCheckDigit" />
          <Field type="hidden" name="Form" />
          <Field type="hidden" name="FormVersion" />

          <Field type="hidden" name="ServiceAddress" />
          <Field type="hidden" name="ServiceCity" />
          <Field type="hidden" name="CustomerName" />
          <Field type="hidden" name="CustomerPhone" />
          <Field type="hidden" name="AssemblyLocation" />
          <Field type="hidden" name="Hazard" />

          <Field type="hidden" name="MainManufacturer" />
          <Field type="hidden" name="MainModelNo" />
          <Field type="hidden" name="MainSerialNo" />
          <Field type="hidden" name="MainSize" />

          <Field type="hidden" name="TesterFirstName" />
          <Field type="hidden" name="TesterLastName" />
          <Field type="hidden" name="TesterPhone" />
          <Field type="hidden" name="TesterCompanyName" />
          <Field type="hidden" name="TesterCompanyPhone" />
          <Field type="hidden" name="TesterCompanyFax" />
          <Field type="hidden" name="TesterEmail" />

          <div className="row">
            <div className="col">
              <h2>Certified Tester Information</h2>
            </div>
          </div>

          <div className="row">
            <div className="col-7">
              <div className="form-group">
                <label>Certification #</label>
                              <SearchInput refVal={this.textInput} name="TesterCertificationNo" onBlur={findTesterByCertificationId} value={values.TesterCertificationNo} />Enter Number and hit tab or select another box
                
                <ErrorMessage className="form-text text-danger" name="TesterCertificationNo" component="div" />
              </div>
            </div>
          </div>
          <div className="row">
            <div className="col">
              <p>
                Tester Name:
                <strong>
                  { values.TesterFirstName || values.TesterLastName ? ` ${values.TesterFirstName} ${values.TesterLastName}` : ' N/A'}
                </strong>
                <br />

                Company Name:
                <strong>
                  { values.TesterCompanyName ? ` ${values.TesterCompanyName}` : ' N/A'}
                </strong>
                <br />

                Company Phone:
                <strong>
                  { values.TesterCompanyPhone ? ` ${formatPhone(values.TesterCompanyPhone)}` : ' --'}
                </strong>
                <br />

                Certificate Expiration Date:
                <strong>
                  { values.TesterCertificationExpDate !== '' ? ` ${dateToHuman(values.TesterCertificationExpDate, 'YYYY/MM/DD')}` : ' --' }
                </strong>
              </p>
              <p>
                <i>
                  Please contact our office if any of your contact information has recently changed.
                </i>
              </p>
            </div>
          </div>

          <hr />
                  <AssemblyTypeFields assemblyType={values.AssemblyType} assemblyTypeDscrpt={this.props.notification.AssemblyTypeDscrpt} />
          <hr />
          {

            values.FirelineFlushRequired === 'Y'
            && (
              <React.Fragment>
                <div className="row">
                  <div className="col">
                    <h2>Fireline Flushing Acknowledgement<br/>(Flushing time must be at least {this.props.notification.FirelineFlushingTime} minutes)</h2>
                  </div>
                </div>

                <div className="row">
                  <div className="col">
                    <div className="form-group">
                      <label>Name</label>
                            <Field name="FirelineFlushingName" className="form-control" /><ErrorMessage className="form-text text-danger" name="FirelineFlushingName" component="div" />
                            <span><Checkbox name="FirelineFlushAck" checked={this.props.values.FirelineFlushAck} />&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;I acknowledge fire flush completed</span>
                            <ErrorMessage className="form-text text-danger" name="FirelineFlushAck" component="div" />
                    </div>
                  </div>
                  <div className="col">
                    <div className="form-group">
                      <label>Fireline Flush Date</label>
                      <W1DatePicker name="FirelineFlushDate" />
                      <ErrorMessage className="form-text text-danger" name="FirelineFlushDate" component="div" />
                    </div>
                  </div>
                </div>
                <hr />
              </React.Fragment>
              )
                  }

            <input type="checkbox" checked={this.state.IsIncorrectInformation} onChange={handleCheckboxOnChange} />&nbsp;&nbsp;Check this box if the information on the left is incorrect

            {
                this.state.IsIncorrectInformation === true &&
            (
                <React.Fragment>
                <div className="row">
                    <div className="col-8">
                      <div className="form-group">
                        <Textarea name="TestComments" placeholder="Only put in infomation that needs correction" />
                        <ErrorMessage className="form-text text-danger" name="TestComments" component="div" />
                      </div>
                    </div>
                </div>
                </React.Fragment>                   
            )
        }

          <div className="row actions">
            <div className="col-12 mb-5">
              {
                status
                && status.formSubmissionError
                && (
                <div className="form-text text-danger">
                  <p>There was an error submitting the form:</p>
                  <p>{`${status.formSubmissionError}`}</p>
                </div>
                )
              }

                {
                        Object.keys(this.props.errors).length > 0 && (
                                <div className="form-text text-danger">
                                Error Summary
                                {Object.values(this.props.errors).map(error => (
                                    <div>{error}</div>
                                ))}
                            </div>
                        )
                }
            </div>

            <div className="col-3 text-center">
                          <button type="reset" className="btn btn-sm btn-outline-primary" onClick={() => {
                              handleReset();
                              this.state.IsIncorrectInformation = false;
                              this.focusTextInput();
                          }}>
                Reset Form
              </button>
            </div>
            <div className="col-4 text-center">
              <button type="button" className="submit btn btn-sm btn-primary" onClick={submitForm} disabled={isSubmitting || values.TesterCertificationExpDate == ''}>
                Submit
              </button>
            </div>
          </div>
        </form>
      </div>
    );
  }
}

export default withFormik({

    validateOnChange: false,
    validateOnBlur: false,
  
  mapPropsToValues: props => (
    {
      TestType: 'AN',
      TestDate: undefined,
      NotificationNo: props.notification.NotificationNo,
      NotificationCheckDigit: props.notification.NotificationCheckDigit,
      Form: 'DWQ-BPAT',
      FormVersion: '0001',
      ServiceAddress: props.notification.Street,
      ServiceCity: props.notification.City,
      CustomerName: props.notification.CustomerName,
      CustomerPhone: props.notification.Telephone,
      AssemblyLocation: props.notification.Location,
      Hazard: props.notification.HazardCodeDscrpt,
      MainManufacturer: props.notification.Manufacturer,
      MainModelNo: props.notification.ModelNo,
      MainSerialNo: props.notification.SerialNo,
      MainSize: props.notification.Size,
      BypassNotificationNo: '',
      BypassNotificationCheckDigit: '',
      BypassManufacturer: '',
      BypassModelNo: '',
      BypassSerialNo: '',
      BypassSize: '0.000',
      AssemblyType: props.notification.AssemblyType,
      FirelineFlushRequired: props.notification.FirelineFlushRequired,
      MainCheckValve1Psid: '0.0',
      MainCheckValve2Psid: '0.0',
      MainReliefValvePsid: '0.0',
      BypassCheckValve1Psid: '0.0',
      BypassCheckValve2Psid: '0.0',
      BypassReliefValvePsid: '0.0',
      VBAirInletPsid: '0.0',
      VBChkVlvPsid: '0.0',
      AirGapSupplySize: '0.0',
      AirGap: '0.0',
      FirelineFlushDate: undefined,
      FirelineFlushAck: false,
      FirelineFlushingName: '',
      TestComments: '',
      TesterFirstName: '',
      TesterLastName: '',
      TesterPhone: '',
      TesterCertificationNo: '',
      TesterCertificationExpDate: '',
      TesterCompanyName: '',
      TesterCompanyPhone: '',
      TesterCompanyFax: '',
      TesterEmail: 'german.rodriguez@metova.com',
    }
  ),


  validationSchema: Yup.object().shape({
      TesterCertificationNo: Yup.string().required('Certification # Required'),
      TestDate: Yup.date().required('Test Date Required'),
    FirelineFlushingName: Yup.string()
      .when('FirelineFlushRequired', {
        is: 'Y',
        then: Yup.string().required('Fireline Flush Name Required'),
      }),
    FirelineFlushDate: Yup.date()
      .when('FirelineFlushRequired', {
        is: 'Y',
        then: Yup.date().required('Fireline Flush Date Required'),
      }),
    FirelineFlushAck: Yup.boolean()
      .when('FirelineFlushRequired', {
          is: 'Y',
          then: Yup.boolean().oneOf([true], 'You must acknowledge the fireline flush') 
      })          
  }),


    handleSubmit: (values, { setStatus, setSubmitting, props }) => {
    const { FirelineFlushRequired, ...data } = values;
      const notificationId = data.NotificationNo || '';

      // remove FirelineFlushAck property because can't send to SAP svc
      delete data.FirelineFlushAck;

        if (data.AssemblyType === "BF-AG" || FirelineFlushRequired === 'Y')
          data.MainSize = "0.000";

    // per Brian B, delete FireLineFlushDate if not required
    if (FirelineFlushRequired !== 'Y')
        delete data.FirelineFlushDate;
    else
            data.FirelineFlushDate = new Date(data.FirelineFlushDate).toISOString();

        //console.log('FirelineFlushDate', data.FirelineFlushDate);

    // per Brian B, remove spaces and dashes from CustomerPhone
    data.CustomerPhone = data.CustomerPhone.split(' ').join('').split('-').join('');
    
    // remove notification number and checkdigit
    // if submission is RP (Replace) or RM (Remove)
    if (data.TestType === 'RP' || data.TestType === 'RM') {
        data.NotificationNo = ''; data.NotificationCheckDigit = '';
    }

    // we must change this to ISO format for the new encoding requirement
        data.TestDate = new Date(data.TestDate).toISOString();

      //console.log('tt=' + data.TestType);
      //console.log('no=' + data.NotificationNo);
      //console.log(data);
      //console.log(JSON.stringify(data));

      testFormService.submitForm(notificationId, data)
          .then((result) => {
              //console.log(result);
        // this means previous promise was successful.
        props.updateState({
          redirectToForm: false,
          redirectToHome: true,
          notification: {},
          alert: {
            type: 'info',
            message: 'Thank you for your submission',
          },
        });
      })
          .catch((err) => {
              //console.log(err);
              //console.log(err.response);
          const { error } = JSON.parse(err.response.data);
          if (error.message.value.includes('Multiple'))
          {
              // handle multiple errors thrown
              var errorMessage = error.innererror.errordetails
                  .filter(e => e.code === '')
                  .map(e => e.message)
                  .join('\r\n');
          }
          else
              var errorMessage = error.message.value;
        setStatus({ formSubmissionError: errorMessage });
      })
      .finally(() => {
        setSubmitting(false);
      });
  },
  // allows the form to be initialized again if props change.
  enableReinitialize: true,
})(RecordForm);