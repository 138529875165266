/* eslint-disable class-methods-use-this */
import { apiService } from './api';
//import rowData from '../screens/OpenBackflowNotifications/DEV_Notification_List_JSON.json'; //mock json file of data

class NotificationsService {
    getNotification(id) {
        //console.log('id', id);
        return apiService.get(`TestNotificationSet('${id}')`);
    }

    getNotifications() {
        return apiService.get(`TestNotificationSet()`);
        //return rowData.d.results;   //json file mock
    }

    getByManufacturer(manufacturer, assemblyType, model, serial) {
        manufacturer = manufacturer.includes('/') ? manufacturer.replace('/', '%252F') : manufacturer;
        assemblyType = encodeURIComponent(assemblyType);
        model = model.includes('/') ? model.replace('/', '%252F') : model;
        serial = encodeURIComponent(serial);

        return apiService.get(`TestNotificationSet%3f$filter=Manufacturer eq ~${manufacturer}~ and AssemblyType eq ~${assemblyType}~ and ModelNo eq ~${model}~ and SerialNo eq ~${serial}~`);
    }

    //getByManufacturer(manufacturer, assemblyType, model, serial) {
    //    return apiService.get(`TestNotificationSet`);
    //}
}

export const notificationsService = new NotificationsService();
