import React from 'react';
import PropTypes from 'prop-types';
import { Field, ErrorMessage } from 'formik';
import { FaSearch } from 'react-icons/fa';

const SearchInput = ({ name, placeholder, onBlur, refVal }) => (
  <div className="input-group">
        <Field
            type="text"
            className="form-control"
            placeholder={placeholder}
            name={name}
            onBlur={onBlur}
            autoComplete="on"
            innerRef={refVal}
    />
        {/*<div className="input-group-append">
      <span className="input-group-text">
        <FaSearch />
      </span>
    </div>*/}
  </div>
);

SearchInput.defaultProps = {
    placeholder: '',
    onBlur: () => { },
    refVal: null
};

SearchInput.propTypes = {
    name: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onBlur: PropTypes.func,
    refVal: PropTypes.shape({ current: PropTypes.elementType })
};

export default SearchInput;
