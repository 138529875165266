import React, { useState, useEffect, useCallback, useRef, useContext } from 'react';
import { AgGridReact } from 'ag-grid-react';
import 'ag-grid-enterprise';
import { LicenseManager } from 'ag-grid-enterprise'
import moment from 'moment';
import FormContext from '../../context/form-context';
import { ReCaptcha } from 'react-recaptcha-google';
import { notificationsService } from '../../services/notifications-service';

LicenseManager.setLicenseKey("Using_this_AG_Grid_Enterprise_key_( AG-044884 )_in_excess_of_the_licence_granted_is_not_permitted___Please_report_misuse_to_( legal@ag-grid.com )___For_help_with_changing_this_key_please_contact_( info@ag-grid.com )___( WaterOne )_is_granted_a_( Single Application )_Developer_License_for_the_application_( Backflush )_only_for_( 1 )_Front-End_JavaScript_developer___All_Front-End_JavaScript_developers_working_on_( Backflush )_need_to_be_licensed___( Backflush )_has_been_granted_a_Deployment_License_Add-on_for_( 1 )_Production_Environment___This_key_works_with_AG_Grid_Enterprise_versions_released_before_( 28 September 2024 )____[v2]_MTcyNzQ3ODAwMDAwMA==9537ef2a0cd57952b9c875d303e2f77b");

const OpenBackflowNotifications = () => {
    const formContext = useContext(FormContext);

    // This effect runs once, after the first render
    useEffect(() => {
        document.title = "Open Backflow Notifications";
    }, [formContext.reCAPTCHAToken]);

    const excelStyles = [
        // The base style, red font.
        {
            id: "redFont",
            font: { color: "#FF0000", bold: true, size: "14" },
            alignment: { vertical: "Center", horizontal: "Center", wrapText: true },
            //    interior: {
            //        color: "#FF0000", pattern: 'Solid'
            //    }
        },
        {
            id: 'dateUS',
            dataType: 'DateTime',
            numberFormat: {
                format: 'mm/dd/yyyy'
            }
        },
        // The cellClassStyle: background is green and font color is light green,
        // note that since this excel style it's defined after redFont
        // it will override the red font color obtained through cellClass:'red'
        {
            id: "greenBackground",
            alignment: {
                horizontal: 'Right', vertical: 'Bottom'
            },
            borders: {
                borderBottom: {
                    color: "#000000", lineStyle: 'Continuous', weight: 1
                },
                borderLeft: {
                    color: "#000000", lineStyle: 'Continuous', weight: 1
                },
                borderRight: {
                    color: "#000000", lineStyle: 'Continuous', weight: 1
                },
                borderTop: {
                    color: "#000000", lineStyle: 'Continuous', weight: 1
                }
            },
            font: { color: "#e0ffc1" },
            interior: {
                color: "#008000", pattern: 'Solid'
            }
        },
        {
            id: "cell",
            alignment: {
                horizontal: "Left",
                //vertical: "Center"
            }
        }
    ];

    const getRows = () => [
        {
            height: 50,
            cells: [
                {
                    data: {
                        value: 'It is a violation of Kansas law for any person to knowingly sell, give, or receive, for the purpose of selling or offering for sale any property or service to persons listed therein, any list of names and addresses contained in or derived from public records.',
                        type: 'String'
                    }, styleId: "redFont", mergeAcross: 11
                },
            ],
        },
    ];

    const gridRef = useRef();

    function myCellCallback(params) {
        if (params.column.colId == "NotificationDate" || params.column.colId == "DueDate")
            return moment(params.value).toISOString()
        else if (params.column.colId == "FirelineFlushReqDscrpt" && !params.value)
            return "NO";
        else
            return params.value;
    }

    const getParams = () => ({
        prependContent: getRows(),
        processCellCallback: myCellCallback,
        sheetName: "Open Backflow Notifications"
    });

    const defaultColDef = {
        sortable: true,
        filter: true,
        wrapHeaderText: true,
        autoHeaderHeight: true
    };

    const [columnDefs] = useState([
        { field: 'Street', suppressSizeToFit: true, width: 250 },
        { field: 'AssemblyType', headerName: "Object type" },
        { field: 'Manufacturer', suppressSizeToFit: true },
        { field: 'ModelNo', headerName: "Model number" },
        { field: 'SerialNo', headerName: "Manuf SerialNo." },
        { field: 'Size', headerName: "Size/ dimensions" },
        { field: 'NotificationNo', headerName: "Notification" },
        {
            field: 'NotificationDate', headerName: 'Notif.date', cellClass: 'dateUS'
                ,valueFormatter: (data) => {
                    return moment(data.data.NotificationDate.replace('Z', '')).format('MM/DD/YYYY');
                }
        },
        {
            field: 'DueDate', headerName: 'Required End', cellClass: 'dateUS'
                , valueFormatter: (data) => {
                    return moment(data.data.DueDate.replace('Z', '')).format('MM/DD/YYYY');
                }
        },
        {
            field: 'FirelineFlushReqDscrpt', headerName: 'Flush Req Desc', valueFormatter: (data) => {
                return data.data.FirelineFlushReqDscrpt ? data.data.FirelineFlushReqDscrpt : "NO";
            }
        },
        {
            field: 'FirelineFlushingTime', headerName: 'Flush Time (minutes)'
        }
    ]);

    function onFirstDataRendered(params) {
        params.api.sizeColumnsToFit();
    }

    async function getNotifications() {
        const response = await notificationsService.getNotifications();

        if (response.status != 200) {
            throw new Error(`HTTP error: ${response.status}`);
        }

        //console.log('data', JSON.parse(response.data).d.results);
        gridRef.current.api.setRowData(JSON.parse(response.data).d.results);
    }

    const onBtExport = useCallback(() => {
        gridRef.current.api.exportDataAsExcel(getParams());
    }, []);

    //console.log('rowData', rowData);

    return (
        <div style={{ width: 1550, marginLeft: 'auto', marginRight: 'auto' }}>
            <h1>Open Backflow Notifications</h1><div style={{ color: 'red', fontSize: '14pt', fontWeight: 'bold' }}>It is a violation of Kansas law for any person to knowingly sell, give, or receive, for the purpose of selling or offering for sale any property or service to persons listed therein, any list of names and addresses contained in or derived from public records.</div><a href='\' target='_blank' title='Click to open the Backflow Assembly Test Form in a new tab'>Backflow Assembly Test Form</a><br></br>
                {
                    !formContext.reCAPTCHAToken
                    ?
                        <ReCaptcha
                            size="large"
                            render="explicit"
                            sitekey="6LfsddwUAAAAAPyIDokSB6JMjpwpG2xwzw_79IGS"
                            onloadCallback={() => { document.getElementById("g-recaptcha").style.display = 'inline-block'; }}
                            verifyCallback={async (token) => {
                                formContext.updateState({ reCAPTCHAToken: token });
                                await getNotifications();
                            }}
                            expiredCallback={() => { formContext.updateState({ reCAPTCHAToken: '' }); }} />
                    :
                        <div>
                            <button
                                onClick={onBtExport}
                                style={{ marginBottom: '5px', fontWeight: 'bold' }}>
                                Export to Excel
                            </button>
                            <div className="ag-theme-alpine" style={{ width: 1500 }}>
                            <AgGridReact
                                domLayout='autoHeight'
                                excelStyles={excelStyles}
                                ref={gridRef}
                                defaultColDef={defaultColDef}                                
                                columnDefs={columnDefs}
                                onFirstDataRendered={onFirstDataRendered}>
                                </AgGridReact>
                            </div>
                        </div>
                }
        </div>
    );
};

export default OpenBackflowNotifications;

