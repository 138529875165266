/* eslint-disable class-methods-use-this */
import { apiService } from './api';

class TestersService {
    findTesterByCertificationId(id) {
        id = encodeURIComponent(id);
    return apiService.get(`TesterSet%3F$filter=CertificateNo eq ~${id}~`);  // encode single quote as tilde ~ to get thru WAF
  }
}

export const testersService = new TestersService();
