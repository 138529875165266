import React, { useEffect } from 'react';
import {
  BrowserRouter,
  Route,
  Switch,
} from 'react-router-dom';
import { loadReCaptcha } from 'react-recaptcha-google';

import FormProvider from './context/form-provider';
import FindRecord from './screens/FindRecord/FindRecord';
import ReportForm from './screens/ReportForm/ReportForm';
import OpenBackflowNotifications from './screens/OpenBackflowNotifications/OpenBackflowNotifications';

const App = () => {
    useEffect(() => {
        loadReCaptcha();
    });

    return (
        <FormProvider>
            <BrowserRouter>
                <Switch>
                    <Route exact path="/" component={FindRecord} />
                    <Route path="/report" component={ReportForm} />
                    <Route path="/notifications" component={OpenBackflowNotifications} />
                </Switch>
            </BrowserRouter>
        </FormProvider>
    );
}

export default App;
